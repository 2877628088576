<template lang="pug">
  .project-details
    section(v-if="project")
      .container: .row
        .col-xs-12.col-md-8.col-lg-6
          article
            h1.article-title {{ project.title }}
            p.article-introduction {{ project.description }}
            p.article-body {{ project.body }}
            a.article-link(v-if="project.url && project.linkText" href="project.url") {{ project.linkText }}


    section.midtone(v-if="project")
      .container: .row
        .col-xs-12
          .logos
            div(v-for="technology in project.technologies")
              img.logo(:src="technology.fields.image")

    section.dark(v-if="project")
      .container: .row
        .col-xs-12
          img.project__image(:src="imageUrl(project.image)")


</template>

<script>
import Project from "@/contentful/Project";

export default {
  name: "projects",
  data: () => {
    return {
      project: null
    };
  },
  async created() {
    const slug = this.$route.params.slug;
    this.project = await Project.get(slug);
  },
  methods: {
    imageUrl: image => {
      return (
        process.env.VUE_APP_CLOUDINARY_URL + "/c_scale,q_100,w_1000/" + image
      );
    }
  }
};
</script>

<style scoped lang="sass">
article
  h1
    font-size: 3rem
  p
    font-size: 1.2rem


.article-link
  color: dodgerblue
  font-size: 1.2rem
  text-decoration: none
  transition: all 0.5s ease

  &:hover
    color: blue
    text-decoration: underline

.project__image
  width: 100%


.logos
  width: 100%
  flex-wrap: wrap
  align-items: center
  flex-direction: row
  display: inline-flex
  justify-content: center

  .logo
    width: 180px
    margin: 2rem
    filter: grayscale(100%);
</style>
