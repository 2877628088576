import contentful from "@/contentful";

export default {
  async get(slug) {
    const entries = await contentful.getEntries({
      content_type: "projects",
      "fields.slug[in]": slug
    });
    const parsedData = contentful.parseEntries(entries);
    console.log(parsedData);
    return parsedData.items[0].fields;
  }
};
